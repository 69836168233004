import React, { ReactNode } from 'react'
import JohnCutler from '../../assets/images/circle/john-cutler.png'
import MeriWilliams from '../../assets/images/circle/meri-williams.png'
import NathanHarvey from '../../assets/images/circle/nathan-harvey.png'
import OttoHilska from '../../assets/images/circle/otto-hilska.png'
import RebeccaMurphey from '../../assets/images/circle/rebecca-murphey.png'
import AlexPlugaru from '../../assets/images/circle/alex-plugaru.png'
import EmilyNakashima from '../../assets/images/circle/emily-nakashima.png'
import MaxKanatAlexander from '../../assets/images/circle/max-kanat-alexander.png'

export type Speaker = {
  name: string
  title: string
  company: string
  image: string
  linkedin: string
}

export const speakers: Speaker[] = [
  {
    name: 'John Cutler',
    title: 'Head of Product',
    company: 'Dotwork',
    image: JohnCutler,
    linkedin: 'https://www.linkedin.com/in/johnpcutler/',
  },
  {
    name: 'Nathen Harvey',
    title: 'DORA Lead',
    company: 'Google Cloud',
    image: NathanHarvey,
    linkedin: 'https://www.linkedin.com/in/nathen/',
  },
  {
    name: 'Meri Williams',
    title: 'CTO',
    company: 'Pleo',
    image: MeriWilliams,
    linkedin: 'https://www.linkedin.com/in/meriwilliams/',
  },
  {
    name: 'Otto Hilska',
    title: 'CEO',
    company: 'Swarmia',
    image: OttoHilska,
    linkedin: 'https://www.linkedin.com/in/hilska',
  },
  {
    name: 'Rebecca Murphey',
    title: 'Field CTO',
    company: 'Swarmia',
    image: RebeccaMurphey,
    linkedin: 'https://www.linkedin.com/in/rmurphey/',
  },
  {
    name: 'Alex Plugaru',
    title: 'CTO',
    company: 'Gorgias',
    image: AlexPlugaru,
    linkedin: 'https://www.linkedin.com/in/313373/',
  },
  {
    name: 'Emily Nakashima',
    title: 'VP of Engineering',
    company: 'Honeycomb',
    image: EmilyNakashima,
    linkedin: 'https://www.linkedin.com/in/eanakashima',
  },
  {
    name: 'Max Kanat-Alexander',
    title: 'Executive Distinguished Engineer',
    company: 'Capital One',
    image: MaxKanatAlexander,
    linkedin: 'https://www.linkedin.com/in/mkanat',
  },
]

export type Talk = {
  speaker: string
  time: string
  title: string
  description: ReactNode
}

export const agenda: Talk[] = [
  {
    speaker: 'Otto Hilska',
    time: '12:00',
    title: 'Welcome: The three pillars of effective engineering organizations',
    description: (
      <>
        The three pillars provide a framework for measuring and improving
        engineering effectiveness across three interlinked perspectives:
        business outcomes, developer productivity, and developer experience.
        <br />
        <br />
        Otto Hilska is the founder and CEO of Swarmia. He is an experienced
        software engineering leader and SaaS entrepreneur. Before Swarmia, Otto
        co-founded Flowdock (acquired by Rally Software) and scaled Smartly.io
        from 30 to 350 employees as the Chief Product Officer.
      </>
    ),
  },
  {
    speaker: 'John Cutler',
    time: '12:20',
    title: 'Your operating system as a design problem',
    description: (
      <>
        We tend to think of ways of working as a boring process question. In
        this talk, John will challenge that belief by offering an alternative
        perspective grounded in service design, behavior design, platform
        thinking, and more.
        <br />
        <br />
        John Cutler is an experienced product development leader who currently
        works as the Head of Product at Dotwork. Having previously worked at
        companies like Toast, Amplitude, and Zendesk, John loves wrangling
        complex problems and answering the why with a mix of qualitative and
        quantitative data. John is the author of the hugely popular product
        development Substack, The Beautiful Mess.
      </>
    ),
  },
  {
    speaker: 'Nathen Harvey',
    time: '1:00',
    title:
      'Get better at getting better: A research-backed approach to measuring and improving the impact of technology-driven teams',
    description: (
      <>
        DORA has spent over a decade uncovering the secrets of high-performing
        tech teams. Their latest research reveals the impact of AI on software
        development, showing a clear link between AI adoption and productivity,
        code quality, and developer happiness. But it’s not all sunshine and
        rainbows — AI also presents challenges that teams need to navigate
        carefully. In this session, Nathen will share actionable insights and
        proven strategies for measuring and improving your team’s performance.
        <br />
        <br />
        Nathen Harvey is DORA Lead and Developer Advocate at Google Cloud. DORA
        enables teams and organizations to thrive by making industry-shaping
        research accessible and actionable. Nathen has learned and shared
        lessons from some incredible organizations, teams, and open source
        communities. He is a co-author of multiple DORA reports on software
        delivery performance and was a contributor and editor for 97 Things
        Every Cloud Engineer Should Know, published by O’Reilly in 2020.
      </>
    ),
  },
  {
    speaker: 'Meri Williams',
    time: '1:40',
    title: '5 things I wish I’d known sooner about scaling',
    description: (
      <>
        What does it take to grow fast AND retain the bits of your culture as a
        team or organization that made you great to begin with? How do you know
        when to change how you do things, without just cargo culting? Having
        scaled a number of teams at different speeds, Meri will talk through
        some of the inflection points you’ll experience, how to navigate them,
        and reflect on all the things they wish they’d known just a little bit
        earlier.
        <br />
        <br />
        Meri Williams is an experienced CTO and leader of technology
        organizations, currently leading the technology team at Pleo. They have
        led teams ranging in size from 30 to 300, in a range of organizations
        from Procter & Gamble, to the Government Digital Service, award-winning
        online print company MOO, mobile-first challenger bank Monzo and
        patient-inspired AI-driven rare disease treatment discovery company
        Healx, amongst others. A published author and international speaker,
        they are the chair of the LeadDev conference, and a tech advisor for
        Kindred Capital. Together with their wife, they run micro-charity One
        Goes Up to help young women & enby folks pursue STEM education &
        careers.
      </>
    ),
  },
  {
    speaker: 'Panel discussion',
    time: '2:20',
    title: 'Setting goals around engineering effectiveness',
    description: (
      <>
        In this session, Rebecca Murphey, Field CTO at Swarmia, will talk with
        seasoned engineering leaders to get their take on goal-setting around
        business outcomes, developer productivity, and developer experience.
        <br />
        <br />
        The panel will be joined by Emily Nakashima, VP of Engineering at
        Honeycomb, Alex Plugaru, Co-Founder & CTO at Gorgias, and Max
        Kanat-Alexander, Executive Distinguished Engineer at Capital One.
      </>
    ),
  },
]
