import React, { ReactNode } from 'react'
import FooterBackgroundMobile from '../../assets/images/circle/footer-bg.svg'
import MetaImage from '../../assets/images/meta/circle-new.png'
import LinkedIn from '../../assets/images/social-icons/linkedin-blue.svg'
import { SpaceScaleValue } from '../../styles/stylePropTypes'
import { DarkLogoBlock } from '../blocks/DarkLogoBlock'
import Layout from '../Layout'
import Box, { BoxProps } from '../primitives/Box'
import { agenda, speakers } from './agenda'
import { CircleHero } from './CircleHero2'
import { RegistrationFormSection } from './RegistrationFormSection'

export default function CirclePage() {
  return (
    <Layout
      titleOverride="Swarmia Circle"
      description="Thanks for joining Swarmia Circle, the virtual event for software engineering leaders and managers. Catch event recordings from speakers including John Cutler, Nathen Harvey, and Meri Williams."
      isNew
      variant="dark"
      metaImage={MetaImage}
    >
      <CircleHero />
      <Box backgroundColor="circleBackground">
        <Content>
          {/* We'll keep SpeakersSection visible, but hide Agenda below */}
          <SpeakersSection />
          <div>
            <RegistrationFormSection />
          </div>

          {/* Hide the logo block: "These companies will join us on stage" */}
          {false && <CompaniesSection />}

          {/* Hide the Agenda */}
          {false && <AgendaSection />}
        </Content>

        {/* Hide the CTA in the footer */}
        {false && <FooterSection />}
      </Box>
    </Layout>
  )
}

function Content(props: { children: ReactNode }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={12}
      color="white"
      paddingTop={{ xs: 32, md: 92 }}
      paddingBottom={{ xs: 16, md: 48 }}
      paddingX={{ xs: 16, md: 48 }}
      maxWidth={1120}
      marginX="auto"
    >
      {props.children}
    </Box>
  )
}

function CompaniesSection() {
  return (
    <Box>
      <CircleH2>These companies will join us on stage</CircleH2>
      <DarkLogoBlock variant="circle" title={null} hideBackground />
    </Box>
  )
}

function SpeakersSection() {
  return (
    <Box>
      <CircleH2>Speakers</CircleH2>
      <Box
        marginTop={64}
        marginBottom={64}
        display="grid"
        gridRowGap={32}
        gridTemplateColumns={{
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
      >
        {speakers.map(speaker => {
          return (
            <Box
              key={speaker.name}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img width={161} src={speaker.image} alt={speaker.name} />
              <Box display="flex" marginTop={12} gap={4}>
                <Box font="small" fontWeight={700}>
                  {speaker.name}
                </Box>
                <Box.a
                  href={speaker.linkedin}
                  css={`
                    img {
                      vertical-align: top;
                    }
                  `}
                >
                  <img
                    width={20}
                    height={20}
                    src={LinkedIn}
                    alt="LinkedIn Logo"
                  />
                </Box.a>
              </Box>
              <Box
                font="small"
                fontSize={14}
                color={'#9AA9CE' as BoxProps['color']}
                marginTop={0}
              >
                {speaker.title}
              </Box>
              <Box
                font="small"
                fontSize={14}
                color={'#9AA9CE' as BoxProps['color']}
              >
                {speaker.company}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

function AgendaSection() {
  return (
    <Box>
      <CircleH2>Agenda</CircleH2>
      <Box marginTop={64} display="flex" flexDirection="column" gap={8}>
        {agenda.map(talk => {
          return (
            <Box.details
              key={talk.title}
              padding={32}
              borderColor={'#50659A' as BoxProps['color']}
              borderRadius={16}
              css={`
                summary::-webkit-details-marker {
                  display: none;
                }
                summary::after {
                  content: '+';
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                  font-size: 32px;
                }
                &[open] > summary::after {
                  content: '-';
                }
              `}
            >
              <Box.summary
                font="large"
                cursor="pointer"
                position="relative"
                css={`
                  list-style: none;
                `}
              >
                <Box paddingRight={32}>
                  {talk.time}
                  <Divider />
                  {talk.title}
                  <Divider />
                  {talk.speaker}
                </Box>
              </Box.summary>
              <Box
                marginTop={32}
                font="large"
                color={'#C1CCE7' as BoxProps['color']}
              >
                {talk.description}
              </Box>
            </Box.details>
          )
        })}
      </Box>
    </Box>
  )
}

function Divider() {
  return (
    <Box.span
      position="relative"
      top={-2}
      color={'#50659A' as BoxProps['color']}
      marginX={8}
    >
      |
    </Box.span>
  )
}

function FooterSection() {
  return (
    <Box
      color="white"
      textAlign="center"
      paddingTop={{ xs: 32, md: 120 }}
      paddingBottom={{ xs: 92, md: 180 as SpaceScaleValue }}
      backgroundImage={{ xs: `url(${FooterBackgroundMobile})`, md: undefined }}
      backgroundRepeat="no-repeat"
      backgroundPosition="50% 100%"
      backgroundSize="100%"
    >
      <Box.h2 font="circleH2" marginBottom={48}>
        Save your seat today
      </Box.h2>
      {/* The registration button has been removed */}
    </Box>
  )
}

function CircleH2(props: { children: ReactNode }) {
  return (
    <>
      <Box.h2 font="large" textAlign="center" marginTop={32}>
        {props.children}
      </Box.h2>
      <HrMiddle />
    </>
  )
}

function HrMiddle() {
  return (
    <Box
      height={2}
      width={300}
      marginTop={16}
      marginBottom={32}
      marginX="auto"
      css={`
        background: linear-gradient(
          90deg,
          rgba(157, 147, 246, 0) 0%,
          #547eed 40%,
          #1cba60 60%,
          rgba(92, 219, 241, 0) 100%
        );
      `}
    />
  )
}
