import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Box from '../../components/primitives/Box'
import { YoutubeVideo } from '../../components/YoutubeVideo'

const Container = styled.div`
  margin-top: 64px;
  margin-bottom: 32px;
  padding-bottom: 40px;

  .columns {
    display: flex;
    width: 100%;
    margin: auto;
    max-width: 1280px;
    margin-bottom: 80px; /* Increased from 64px to 80px for more spacing */
  }

  .col {
    padding: 0 32px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;

      .col {
        width: 100%;
      }
    }
  }
`

export function RegistrationFormSection() {
  return (
    <Container>
      {/* 1st text + video */}
      <Section
        heading="Reasoning about developer productivity"
        subheading="Speaker: Otto Hilska"
        text="The three pillars provide a framework for measuring and improving engineering effectiveness across three interlinked perspectives: business outcomes, developer productivity, and developer experience."
        videoId="NL40jCtCtgw"
      />

      {/* 2nd text + video */}
      <Section
        heading="Your operating system as a design problem"
        subheading="Speaker: John Cutler"
        text={
          <>
            <p>
              We tend to think of ways of working as a boring process question.
              In this talk, John will challenge that belief by offering an
              alternative perspective grounded in service design, behavior
              design, platform thinking, and more.
            </p>
          </>
        }
        videoId="df7IamWhYLc"
      />

      {/* 3rd text + video */}
      <Section
        heading="Get better at getting better"
        subheading="Speaker: Nathen Harvey"
        text="Nathen shares insights about software delivery performance metrics and the surprising impacts of AI adoption on development teams. His presentation reveals that while AI increases developer job satisfaction and flow, it can temporarily decrease overall throughput and stability."
        videoId="cZKwAtv3_JU"
      />

      {/* 4th text + video */}
      <Section
        heading="5 things I wish I would have known sooner about scaling"
        subheading="Speaker: Meri Williams"
        text="Having scaled a number of teams at different speeds, Meri talks through some of the inflection points you’ll experience, how to navigate them, and reflect on all the things they wish they’d known earlier."
        videoId="WaZS589yoWk"
      />

      {/* 5th text + video */}
      <Section
        heading="Setting goals around engineering effectiveness"
        subheading="Panel: Rebecca Murphey, Emily Nakashima, Alex Plugaru, and Max Kanat-Alexander"
        text="Rebecca Murphey interviews seasoned engineering leaders about their take on goal-setting around business outcomes, developer productivity, and developer experience."
        videoId="mHWfYPguxkc"
      />
    </Container>
  )
}

/** Reusable component for each text+video block */
function Section({
  heading,
  subheading,
  text,
  videoId,
}: {
  heading: string
  subheading?: string
  text: ReactNode
  videoId: string
}) {
  return (
    <div className="columns">
      {/* Left column: text content */}
      <div className="col">
        <Box.h2 font="circleH2">{heading}</Box.h2>
        {subheading && (
          <Box.h3 font="circleH3" marginTop={8} marginBottom={16}>
            {subheading}
          </Box.h3>
        )}
        <HrLeft />
        <Box font="medium">{text}</Box>
      </div>

      {/* Right column: embedded YouTube video */}
      <div className="col">
        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%', // 16:9 aspect ratio
            marginTop: '24px',
            height: 0,
          }}
        >
          <YoutubeVideo videoId={videoId} />
        </div>
      </div>
    </div>
  )
}

function HrLeft() {
  return (
    <Box
      height={2}
      width={130}
      marginTop={16}
      marginBottom={32}
      css={`
        background: linear-gradient(
          90deg,
          #9d93f6 0%,
          #547eed 20%,
          #1cba60 40%,
          rgba(92, 219, 241, 0) 100%
        );
      `}
    />
  )
}
